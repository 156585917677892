import {Controller} from "@hotwired/stimulus"
import AutoNumeric from 'autonumeric'

// Connects to data-controller="commission"
export default class extends Controller {
    static targets = ["ask", "askAll", "commission", "commissionAll", "input", "inputMarket", "locale", "quantity", "submit", "total", "totalAll", "value", "price"]
    numberFormat;

    connect() {
        console.log('--- connect');
        const locale = this.localeTarget.value;
        this.numberFormat = new Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR'});
        this.askTarget.innerHTML = this.numberFormat.format(0);
        this.commissionTarget.innerHTML = this.numberFormat.format(0);
        this.totalTarget.innerHTML = this.numberFormat.format(0);
        this.inputTarget.value = this.numberFormat.format(0);
        this.inputMarketTarget.value = this.numberFormat.format(0);

        const quantity = this.quantityTarget.value;
        if (quantity > 1) {
            this.askAllTarget.innerHTML = this.numberFormat.format(0);
            this.commissionAllTarget.innerHTML = this.numberFormat.format(0);
            this.totalAllTarget.innerHTML = this.numberFormat.format(0);
        }

        const autoNumericConfig = {
            digitGroupSeparator: '.',
            decimalCharacter: ',',
            decimalPlaces: 2,
            // currencySymbol: '€'
            // https://github.com/autoNumeric/autoNumeric
        };

        this.autoNumeric = new AutoNumeric(this.inputTarget, autoNumericConfig);
        this.autoNumericMarket = new AutoNumeric(this.inputMarketTarget, autoNumericConfig);
    }

    updateTable(price, quantity) {
        const ask = this.askTarget;
        const commission = this.commissionTarget;
        const total = this.totalTarget;

        ask.innerHTML = this.numberFormat.format(price);
        commission.innerHTML = this.numberFormat.format(price * 0.1);
        total.innerHTML = this.numberFormat.format(price * 1.1);

        if (quantity > 1) {
            const askAll = this.askAllTarget;
            const commissionAll = this.commissionAllTarget;
            const totalAll = this.totalAllTarget;
            askAll.innerHTML = this.numberFormat.format(price * quantity);
            commissionAll.innerHTML = this.numberFormat.format(price * 0.1 * quantity);
            totalAll.innerHTML = this.numberFormat.format(price * 1.1 * quantity);
        }

    }

    updateCommission() {
        const quantity = this.quantityTarget.value;
        const priceInput = this.priceTarget;

        const price = this.autoNumeric.getNumber();
        priceInput.value = price;

        this.autoNumericMarket.set(price * 1.1)

        this.setSubmitButtonState(price);
        this.updateTable(price, quantity);
    }

    updateCommissionByMarketPrice(event) {
        const quantity = this.quantityTarget.value;
        const priceInput = this.priceTarget;

        const price = this.autoNumericMarket.getNumber();
        priceInput.value = price / 1.1;

        this.autoNumeric.set(price / 1.1)

        this.setSubmitButtonState(price);
        this.updateTable(price / 1.1, quantity);
    }

    setSubmitButtonState(price) {
        const submitButton = this.submitTarget;

        if (price && price > 0) {
            submitButton.removeAttribute("disabled")
        } else {
            submitButton.setAttribute("disabled", true)
        }
    }

    disconnect() {
        // Vor dem Entfernen wird AutoNumeric sauber beendet
        this.autoNumeric.remove()
    }

}
